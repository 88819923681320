<template lang="pug">
  button.app-button.btn(
    :class="{ disabled }"
    :title="translatedTitle"
    v-bind="$props"
    v-on="$listeners"
  )
    .app-button-content
      slot(name="before")
      Transition(
        name="fade"
        tag="div"
        class="transition-container"
      )
        .title(:key="title") {{ translatedTitle }}
      slot(name="after")
</template>

<script>
  export default {
    props: {
      title: String,
      disabled: {
        type: Boolean,
        default: false
      },
      skipTranslation: {
        type: Boolean,
        default: false
      }
    },

    computed: {
      translatedTitle({ title, skipTranslation }) {
        if (skipTranslation) {
          return title
        } else {
          return this.$t(title)
        }
      }
    }
  }
</script>

<style lang="sass" scoped>
  @import "@/assets/styles/variables.sass"
  @import "@/assets/styles/transitions.sass"

  button.btn
    font-weight: 300
    height: 34px
    padding: 5px 35px
    transition: all 0.2s linear

    &:hover
      box-shadow: 0 2px 8px -3px $default-black

    &:disabled
      cursor: not-allowed
      opacity: 0.6
      border: 1px solid $default-purple-light-line
      &:hover
        box-shadow: none

    .app-button-content
      align-items: center
      display: flex
      font-size: 0.8rem
      justify-content: center

      .title
        white-space: nowrap

    &-confirm
      background-color: $default-green
      .app-button-content
        color: $default-white
      &.short
        padding: 5px 10px

    &-add-new
      background-color: $default-purple
      .app-button-content
        color: $default-white
      &.short
        padding: 5px !important

    &-download
      background-color: $default-white
      border: 1px solid $default-purple
      .app-button-content
        color: $default-purple
      &.short
        padding: 5px 10px

    &-other
      background-color: transparent
      border: 1px solid $default-purple
      .app-button-content
        color: $default-purple
      &.short
        padding: 5px 10px

    &.short
      padding: 5px 10px
</style>
